import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import ResumePage from '../../resume/resume'
import '../../style/resume.scss'

const ResumePrint = () => {
    useEffect(() => {
        window.print()
        navigate('/resume')
    }, [])

    return (
        <div className='Print'>
            <ResumePage />
        </div>
    )
}

export default ResumePrint
